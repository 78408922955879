import "./styles.css"

import createPlayer from "web-audio-player"
import createAnalyser from "web-audio-analyser"
import createAudioContext from "ios-safe-audio-context"
import detectAutoplay from "detect-audio-autoplay"
import detectMediaSource from "detect-media-element-source"
import createApp from "canvas-loop"
import { EventEmitter } from "events"

var canvas = document.getElementById("spectro");
var container = document.getElementById("viz");
console.log(document.getElementsByTagName("pre")[0].innerHTML);

canvas.width = window.innerWidth;
canvas.height = window.innerHeight - 150;

var ctx = canvas.getContext("2d");
var play = document.getElementById("play");

var songs = document.getElementsByClassName("song")

var Media = {
  song: "01",

  events: new EventEmitter(),

  player: false,

  play: (id) => {
    if(Media.player) {
      Media.player.stop();
      Media.player = false;
    }

    Media.song = id;
    Media.events.emit("play", id);

    var audioContext = createAudioContext();
    detectMediaSource((supportsMediaElement) => {
      var shouldBuffer = !supportsMediaElement
      start(audioContext, shouldBuffer);
    }, audioContext);
  },

  playing: () => {
    if(!Media.player) return;

    return Media.player.playing;
  },

  pause: () => {
    if(!Media.player) return;

    Media.events.emit("pause", Media.song);
    Media.player.pause();
  },

  resume: () => {
    Media.events.emit("resume", Media.song);
    if(!Media.player) return;

    Media.player.resume();
  }
};


for(let i=0; i<songs.length; i++) {
  songs[i].addEventListener("click", (e) => {
    e.preventDefault();

    let song = songs[i].dataset.music;

    if(Media.playing() && song == Media.song) {
      Media.pause();
    } else {
      Media.play(song);
    }
  })
}

Media.events.on("play", (id) => {
  let index = parseInt(id) - 1;

  for(let i = 0; i < songs.length; i++) {
    if(i == index) {
      songs[i].classList.remove("play");
      songs[i].classList.add("stop");
    } else {
      songs[i].classList.remove("stop");
      songs[i].classList.add("play");
    }
  }
})

Media.events.on("pause", (id) => {
  let index = parseInt(id) - 1;
  songs[index].classList.remove("stop");
  songs[index].classList.add("play");
})

function start(audioContext, shouldBuffer) {
  Media.events.emit("start");

  var sources = ["audio/" + Media.song + ".mp3", "audio/" + Media.song + ".ogg"];
  var player = createPlayer(sources, {
    context: audioContext,
    buffer: shouldBuffer,
    loop: true
  });

  Media.player = player;

  var app = createApp(canvas, {
    scale: window.devicePixelRatio,
    parent: container
  })

  var audioUtil = createAnalyser(Media.player.node, Media.player.context, {
    stereo: false
  })

  var analyser = audioUtil.analyser
  analyser.fftSize = 512;

  var x = 0;
  var skippable = 190;
  var barHeight;
  var bufferLength = analyser.frequencyBinCount;
  var renderLength = bufferLength - skippable;
  var barWidth = (app.shape[0]/ bufferLength) * 1.8;

  Media.player.once('decoding', (amount) => {
    console.log("decoding...", amount)
  })

  Media.player.on('end', () => {
    console.log('Audio ended')
  })

  Media.player.on('load', () => {
    Media.player.play();

    app.on('tick', render);
    app.start();
  });

  function draw(dataArray, i, x) {
    barHeight = dataArray[i];

    var r = Math.floor(barHeight + (25 * (i/renderLength)));
    var g = Math.floor(250 * (i/renderLength));
    var b = 50;

    ctx.fillStyle = "rgb(" + r + "," + g + "," + b + ")";
    ctx.fillRect(x, 0, barWidth, barHeight);

    return x + barWidth + 1;
  }

  function render() {
    const [ width, height ] = app.shape;

    ctx.save()
    ctx.scale(app.scale, app.scale)

    ctx.fillStyle = "#000"
    ctx.fillRect(0, 0, width, height)

    x = 0;

    var dataArray = audioUtil.frequencies();
    var fn = (i, x) => { return draw(dataArray, i, x) }

    for (let i =- renderLength, h = renderLength; i < h; i++) {
      x = fn(Math.abs(i), x);
    }

    ctx.fill()
    ctx.restore()
  }

  Media.player.play();
  Media.player.node.connect(Media.player.context.destination)
};
